<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("zone") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.brandIds"
                      v-bind:label="$t('brands.brands')"
                      :items="brand_list"
                      item-text="name"
                      item-value="id"
                      filled
                      multiple
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.geographyIds"
                      v-bind:label="$t('geography')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="label"
                      item-value="id"
                      :search-input.sync="search_geography"
                      :loading="isGeographySearching"
                      :items="filtered_geography"
                      hide-no-data
                      filled
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleSelectAllOptions('geographyIds')"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selected.geographyIds.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                color="blue darken-1"
                :loading="isSaving"
                text
                @click="onSaveClick(selected)"
              >
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              v-if="importExcelTemplatePathName"
              color="primary"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
              class="mb-2 ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefbrandzones"
              id="brandzones_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="brandzones"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalbrandzones"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "Zone",
  data: function() {
    return {
      importExcelTemplatePathName: null,
      filterable_col_list: ["name", "brands", "province"],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params);
        },
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {},
        name: {},
        brands: {},
        province: {},
      },
      showEmpty: false,

      totalbrandzones: 0,
      brandzones: [],
      brand_list: [],

      isSaving: false,
      valid: true,
      requiredRules: [(v) => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      showEdit: false,

      search_geography: null,
      isGeographySearching: false,
      filtered_geography: [],

      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
    };
  },
  watch: {
    async search_geography(val) {
      let filterable = this.filtered_geography.filter(
        (item) => item.label === val
      );
      if (val !== null && val !== "" && filterable.length === 0) {
        this.isGeographySearching = true;
        let fetch_url = "pos/get_geographies?filter_name=" + val;
        this.prevsearch = val;
        const resp = await ApiService.get(fetch_url);
        this.filtered_geography = resp.data;
        this.isGeographySearching = false;
      }
    },
  },
  computed: {
    likesAllGeographies() {
      return (
        this.selected.geographyIds.length === this.filtered_geography.length
      );
    },
    icon() {
      if (this.likesAllGeographies) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: "10%",
          sortBy: "ASC",
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          sortBy: "",
          width: "25%",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`pos/brandzones/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "brands",
          title: this.$t("brands.brand"),
          field: "brands",
          align: "left",
          width: "30%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let brand_names = row.brands.map((item) => item.name);
            return <span>{brand_names.join(", ")}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brands"]}
                    apiUrl={`pos/brandzones/getfilterlist`}
                    columnName={`brands`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brands"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brands")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "province",
          title: this.$t("province"),
          field: "province",
          align: "left",
          width: "25%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row.geography_list.map((geo_item) => {
                  return (
                    <div>
                      <span>
                        {geo_item.province_name !== ""
                          ? geo_item.province_name +
                            ", " +
                            geo_item.state_name +
                            ", " +
                            geo_item.country_name
                          : ""}
                      </span>
                      <br />
                    </div>
                  );
                })}
              </div>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["province"]}
                    apiUrl={`pos/brandzones/getfilterlist`}
                    columnName={`province`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["province"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "province")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            },
          },
        },
        {
          key: "actions",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: "10%",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          },
        },
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
  },
  methods: {
    toggleSelectAllOptions(flag) {
      if (flag === "geographyIds") {
        if (this.likesAllGeographies) {
          this.selected.geographyIds = [];
        } else {
          this.selected.geographyIds = this.filtered_geography.map(
            (el) => el.id
          );
        }
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#brandzones_table"),
        name: "flow", // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        brandIds: _.get(item, "brandIds"),
        brands: _.get(item, "brands"),
        geography_list: _.get(item, "geography_list"),
        geographyIds: _.get(item, "geographyIds"),
      };
      if (!model.geographyIds) model.geographyIds = [];
      if (model.brands) {
        model.brandIds = model.brands.map((item) => item.id);
      }
      if (model.geography_list) {
        model.geographyIds = model.geography_list.map((item) => item.id);
      }
      console.log(model);
      return model;
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = item;
        if (body.id) {
          await ApiService.put(`pos/brandzones/${body.id}`, body);
        } else {
          await ApiService.post(`pos/brandzones`, body);
        }
        logInfo("Zona actualizada");
        this.showEdit = false;
        this.isSaving = false;
        this.getDataFromApi();
      }
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value,
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#brandzones_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map((filter_item) => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/brandzones";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.brandzones = resp.data;
        this.brand_list = resp.brand_list;
        this.filtered_geography = resp.geographies;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        if (this.brandzones.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalbrandzones = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "pos/brandzones/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob",
        });
        download(response, "zones.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `pos/brandzones/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`,
              },
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message,
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfile(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.getDataFromApi();
        } catch (error) {
          console.log(error);
        }
      }
    },
    exportCSVfile(headers, rows) {
      let data = [];
      let header = {};
      let i = 0,
        j = 0;
      for (i = 0; i < headers.length; i++) {
        header[headers[i]] = headers[i];
      }
      console.log(header);
      for (i = 0; i < rows.length; i++) {
        let data_item = {};
        let current_key = "";
        for (j = 0; j < headers.length; j++) {
          current_key = headers[j];
          data_item[current_key] = rows[i][j];
        }
        data.push(data_item);
      }
      console.log(data);
      if (header) {
        data.unshift(header);
      }
      let jsonObject = JSON.stringify(data);
      let csv = this.convertToCSV(jsonObject);
      console.log(csv);
      let exportedFilenmae = "error_zones.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    init() {
      this.getDataFromApi();
    },
  },
  async mounted() {
    this.init();
  },
};
</script>

<style></style>
